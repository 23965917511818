import {bindable, computedFrom, customElement, inject, LogManager} from 'aurelia-framework';
import {ChoiceLoader} from './loader/choice-loader';
import {EventAggregator} from 'aurelia-event-aggregator';
import {I18N} from 'aurelia-i18n';
import {DynamicConditionsBuilder} from '../api/dynamic-conditions-builder';
import {DialogService} from 'aurelia-dialog';
import {UniversalEntitySelect} from '../dialog/universal-entity-select';
import {ModelLabelService} from '../reference/model-label-service';
import {Client} from '../api/client';
import * as _ from 'lodash';
import "jquery-ui/widgets/sortable";
import "@selectize/selectize";
import "@selectize/selectize/dist/css/selectize.bootstrap3.css";
import $ from "jquery";
import {UserClient} from "../api/user-client";
import {fixSelectizeKeyboard} from "./selectize-hacks";

import "./choice-element.less";
import { config } from 'yargs';

const logger = LogManager.getLogger('ChoiceElement');
logger.setLevel(LogManager.logLevel.debug); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    ChoiceLoader,
    EventAggregator,
    I18N,
    DynamicConditionsBuilder,
    DialogService,
    ModelLabelService,
    Client,
    UserClient
)
@customElement('sio-choice-input')
export class ChoiceElement {
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;
    @bindable tabindex;
    @bindable contextObjectRef;

    choiceElementContainer;
    selectize;
    actions;

    loading = false; // When choice is loading some data like new configuration or entities, this field is true. Otherwise, false.
    reloadChoices = true;
    previousConditions = null;

    constructor(
        choiceLoader,
        ea,
        i18n,
        dynamicConditionsBuilder,
        dialogService,
        modelLabelService,
        client,
        userClient
    ) {
        this.choiceLoader = choiceLoader;
        this.ea = ea;
        this.i18n = i18n;
        this.dynamicConditionsBuilder = dynamicConditionsBuilder;
        this.dialogService = dialogService;
        this.modelLabelService = modelLabelService;
        this.client = client;
        this.userClient = userClient;
    }

    @computedFrom('config', 'actions')
    get _widthCalc() {

        if (!this.actions ||
            (this.actions.selectFromTable.length === 0 && (this.actions.createNew.length === 0 || this.config.hideCreateAction))
        ) {
            return 'width: 100%';
        } else if (this.actions.selectFromTable.length === 0 || this.actions.createNew.length === 0 || this.config.hideCreateAction) {
            return 'flex : 1';
        } else {
            return 'flex : 1';
        }
    }

    async attached() {
        this.formPostSubmitSubscription = this.ea.subscribe('sio_form_post_submit', async (event) => {

            logger.debug('RECEIVED FORM RESPONSE', this, event);
            if (
                !this.modelIds.includes(event.config.modelId) ||
                !event.response ||
                event.config.controlUID !== this._getControlUID(this.config)
            ) {
                return;
            }

            logger.debug('RECEIVED FORM RESPONSE', event);

            let items;

            if (!_.isArray(event.response.data)) {
                items = [event.response.data];
            } else {
                items = event.response.data;
            }

            await this.addItemsToChoice(items);
        });

        this._reloadChoices();
    }

    detached() {
        this.formPostSubmitSubscription.dispose();

        if (this.formValueChangedSubscription) {
            this.formValueChangedSubscription.dispose();
        }
    }

    async addItemsToChoice(items) {
        for (let i = 0, iend = items.length; i < iend; ++i) {
            const item = items[i];

            if (!item) continue;

            const itemValue = this._modelReferenceToViewReference(item);

            //Note: label gets replaced in reload choices call anyway, no need to call model label service
            this.selectize.addOption({label: '-', value: itemValue});
            this.selectize.addItem(itemValue);

            logger.debug('Added item', item, itemValue);
        }

        this._reloadChoices();
    }

    /**
     * This callback is executed when value of some form in application is updated.
     *
     * @param event {Object} Object that contains two fields:
     *                       - form : object of Form class that changed value
     *                       - field : object of FormField class that changed value and belongs to specified form
     */
    onFormValueChangedCallback(event) {
        // Verification that choice input belongs to form that fired event.

        if (event.form.formService !== this.config.formService) {
            return;
        }

        // Getting name of property that changed with @ prefix

        let changedProperty = this.dynamicConditionsBuilder.referencePropertyName(event.field.property);

        // Check if it is property change of any dynamic property that we are listening to.
        // If so, update choices list.
        if (
            _.find(
                this.propertiesToListenForChange,
                (propertyToListen) => propertyToListen === changedProperty
            )
        ) {

            if (!_.isEqual(_.cloneDeep(this._getRequestConditions()), this.previousConditions)) {
                //Clear selection to avoid wrong selection
                if (this.config.multiple) {
                    this.value = [];
                } else {
                    this.value = null;
                }

                this._reloadChoices();
            }
        }
    }

    async configChanged(newValue, oldValue) {
        logger.debug('Configuration changed old -> new', oldValue, newValue);

        if (this.config.parent) {
            this.propertiesToListenForChange = this.dynamicConditionsBuilder.getAllDynamicParametersFromConditions(
                this.config.conditions,
                this.config.parent.getValue()
            );

            // If some dynamic conditions are present for choice input,
            // then listen for changes of these parameters

            if (!_.isEmpty(this.propertiesToListenForChange)) {
                if (this.formValueChangedSubscription) {
                    this.formValueChangedSubscription.dispose();
                }

                this.formValueChangedSubscription = this.ea.subscribe(
                    'sio_form_value_changed', this.onFormValueChangedCallback.bind(this)
                );
            }
        }

        if (this.config.set || this.config.modelId || this.config.modelPropertyId) {
            this.config.translateChoiceLabel = false;
        } else if (this.config.translateChoiceLabel == null) {
            this.config.translateChoiceLabel = true;
        }

        this.actions = await this._buildActions();

        if (this.config.modelId) {
            this.modelId = this.config.modelId;
        }

        logger.debug('Determined actions for choice', this.actions);

        this._reloadChoices();
    }

    async _buildActions() {

        const actions = {
            selectFromTable: [],
            createNew: [],
        };

        if (this.config.modelId && !this.config.hideSelectFromTable) {

            const implementations = await this.client.get('interface/implementations/' + this.config.modelId, 60);
            this.modelIds = [this.config.modelId];

            implementations.forEach(model => {
                this.modelIds.push(model.combinedId);

                if (model.selectable) {
                    actions.selectFromTable.push({
                        config: {
                            actionClass: 'btn btn-default btn-sm',
                            label: this.i18n.tr(model.label) + ' auswählen',
                            showLabel: true,
                            icon: ''
                        },
                        modelId: model.combinedId
                    });
                }

                if (model.createAction) {
                    actions.createNew.push({
                        config: {
                            preset: 'new',
                            actionClass: 'btn btn-default btn-sm',
                            label: this.i18n.tr(model.label) + ' erzeugen',
                            showLabel: true,
                            icon: '',
                            viewId: model.createAction.viewId
                        },
                        modelId: model.combinedId
                    });
                }
            });

            if (actions.selectFromTable.length === 1) {
                actions.selectFromTable[0].config.showLabel = false;
                actions.selectFromTable[0].config.icon = 'fa fa-table-list';
            }

            if (actions.createNew.length === 1) {
                actions.createNew[0].config.showLabel = false;
                delete actions.createNew[0].config.actionClass;
                delete actions.createNew[0].config.icon;
            }
        } else {
            this.modelIds = [];
        }

        return actions;
    }

    _getControlUID(config) {
        return config.modelId + '/' + config.fullProperty;
    }

    _viewReferenceToModelReference(element) {
        if (element.startsWith('modelId|')) {
            let parts = element.split('|');

            return {
                id: parts[2],
                modelId: parts[1]
            };
        }

        return element;
    }

    _modelReferenceToViewReference(element) {
        if (_.isObject(element)) {
            return 'modelId|' + element.modelId + '|' + element.id;
        } else {
            return element;
        }
    }

    assignToMe() {
        if (this.userClient.user) {
            if (this.config.multiple) {
                this.value = [
                    {
                        id: this.userClient.user.id,
                        modelId: this.userClient.user.modelId
                    }
                ];
            } else {
                this.value = {
                    id: this.userClient.user.id,
                    modelId: this.userClient.user.modelId
                };
            }

            setTimeout(() => {
                this.choiceElementContainer.dispatchEvent(new CustomEvent('sio-change', {
                    bubbles: true,
                    detail: {value: this.value}
                }));
            }, 0);
        }
    }

    valueChanged(newValue, oldValue) {
        //If reloadChoices is false the change was initiated by a view change, so we do NOT need to update
        //See https://stackoverflow.com/a/45155901
        if (!this.reloadChoices) {
            this.reloadChoices = true;
            return;
        }

        this._reloadChoices();
    }

    change(newValue) {
        const __transformNewValue = (newValue) => {
            if (newValue === '') {
                newValue = null;
            }

            if (newValue) {
                if (_.isArray(newValue)) {
                    newValue = _.map(newValue, element => this._viewReferenceToModelReference(element));
                } else {
                    newValue = this._viewReferenceToModelReference(newValue);
                }
            }

            return newValue;
        };

        newValue = __transformNewValue(newValue);

        logger.debug('Select value changed', newValue);

        this.reloadChoices = false;
        this.value = newValue;

        setTimeout(() => {
            this.choiceElementContainer.dispatchEvent(new CustomEvent('sio-change', {
                bubbles: true,
                detail: {value: this.value}
            }));
        }, 0);
    }

    _getChoiceValue() {
        let choiceValue = [];

        if (_.isArray(this.value)) {
            _.each(this.value, (element, index) => {

                choiceValue.push(this._modelReferenceToViewReference(element));
            });
        } else {
            choiceValue.push(this._modelReferenceToViewReference(this.value));
        }

        return choiceValue;
    }

    _reloadChoices() {


        if (!this.select) {
            return;
        }

        if (this.selectize) {
            this.selectize.destroy();
            this.selectize = null;
        }

        let choiceElement = this;

        let plugins = [];
        if (this.config) {
            if (this.config.multiple || !this.config.required) {
                plugins.push('remove_button');
            }

            if (this.config.sortable) {
                plugins.push('drag_drop');
            }
        }

        let maxItems = null;
        if (!this.config.multiple) {
            maxItems = 1;
        } else if (this.config.maxItems) {
            maxItems = this.config.maxItems;
        }

        let sortField = 'label';
        if (this.config.options && this.config.options.disableSort) {
            sortField = null;
        }
        let matchSearchFromStart = this.config?.matchSearchFromStart || false;
        this.selectize = $(this.select)
            .selectize({
                plugins: plugins,
                valueField: 'value',
                labelField: 'label',
                searchField: 'label',
                sortField: sortField,
                preload: 'focus',
                selectOnTab: this.config.selectOnTab || false,
                maxItems: maxItems,
                load: (search, callback) => {
                    let conditions = _.cloneDeep(this._getRequestConditions());

                    if (search) {
                        if (!conditions || conditions.length === 0) {
                            conditions = {};
                        }
                        //Todo Note: in case of model id filtering happens twice: through api and then selectize filters results as well, so only what is in label gets displayed in results, e.g. search by email does not work
                        //We could improve that in the future
                        conditions.search = search;
                    }

                    this.choiceLoader.getChoices(this.config, conditions).then(items => callback(items.map(item => ({
                        label: this.config.translateChoiceLabel ? this.i18n.tr(item.label) : item.label,
                        value: this._modelReferenceToViewReference(item.value),
                        shortLabel: item.shortLabel,
                        help: this.i18n.tr(item.help),
                        $order: item.$order
                    }))), () => {
                        callback();
                    });
                },
                onChange: this.change.bind(this),
                onDropdownOpen: this.dropdownOpen.bind(this),
                onInitialize: function () {
                    choiceElement.loading = true;

                    if (null == choiceElement.value || (Array.isArray(choiceElement.value) && 0 === choiceElement.value.length)) {
                        choiceElement.loading = false;

                        return;
                    }

                    let initialValues = choiceElement.value;


                    if (!Array.isArray(initialValues)) {
                        initialValues = [initialValues];
                    }

                    choiceElement.previousConditions = _.cloneDeep(choiceElement._getRequestConditions());

                    choiceElement.choiceLoader.getChoices(
                        choiceElement.config, choiceElement.previousConditions,
                        initialValues.map(iv => ({modelId: iv.modelId, id: iv.id}))
                    ).then(data => {

                        const items = data.map(element => ({
                            label: choiceElement.config.translateChoiceLabel ? choiceElement.i18n.tr(element.label) : element.label,
                            shortLabel: element.shortLabel,
                            help: choiceElement.i18n.tr(element.help),
                            value: choiceElement._modelReferenceToViewReference(element.value)
                        }));


                        this.addOption(items);

                        logger.debug('add value', items);

                        // set value if there is only one option
                        if (items.length === 1 && choiceElement.config.required){
                            this.setValue([items[0].value], true);
                        }

                        const lookupMap = {};

                        for (let item of items) {
                            lookupMap[item.value] = 1;
                        }

                        let currentValue = choiceElement._getChoiceValue();

                        for (let currentItem of currentValue) {
                            if (!lookupMap[currentItem]) {
                                this.addOption([{
                                    label: 'Ungültiger Wert',
                                    value: currentValue
                                 }]);
                            }
                        }

                        // if there is already a default value
                        this.setValue(currentValue, true);

                        choiceElement.loading = false;

                        choiceElement.ea.publish('sio-dynamic-choices-reloaded', {label: choiceElement.sanitizedConfigLabel(choiceElement.config.label)});
                    });
                },
                score: function(search) {
                    let score = this.getScoreFunction(search);
                    return function(item) {
                        if(matchSearchFromStart){
                            return item.label.toLowerCase().indexOf(search.toLowerCase()) === 0 ? score(item) : 0
                        }else{
                            return score(item)
                        }
                    };
                },
                render: {
                    item: (item, escape) => '<div class="item '+ (item.label === 'Ungültiger Wert' ? 'text-danger' : '') +'">' + escape(item.shortLabel || item.label) + '</div>',
                    option: (item, escape) => '<div class="option">' + escape(item.shortLabel || item.label) + (item.help != null && item.help != '' ? '<br/><small>' + escape(item.help) + '</small>' : '') +  '</div>',
                }
            })[0].selectize
        ;

        this.selectize.$control_input.on('blur', () => {
            // fix scrolling to the left, see https://github.com/selectize/selectize.js/issues/1244
            this.selectize.$control_input.css({
                opacity: 0,
                position: 'fixed',
                left: this.selectize.rtl ? 10000 : -10000
            });
        });

        fixSelectizeKeyboard(this.selectize, this.config);
    }

    async selectFromTable(modelId) {
        if (!modelId) {
            return;
        }

        const data = await this.dialogService.open({
            viewModel: UniversalEntitySelect, model: {
                selectModelId: modelId,
                currentValue: this.value,
                currentValueConfig: this.config,
                multiSelect: !!this.config.multiple,
                conditions: this._getRequestConditions()
            }
        }).whenClosed();

        const items = data.output;

        if (!items || items.length === 0) {
            return;
        }

        await this.addItemsToChoice(items);
    }

    _getRequestConditions() {
        let requestConditions = this.config.conditions;

        if (this.propertiesToListenForChange) {
            requestConditions = this.dynamicConditionsBuilder.buildDynamicConditions(
                this.config.conditions,
                this.config.parent.getValue()
            );
        }

        return requestConditions;
    }

    getConfigOptions(config) {
        return config.options;
    }

    dropdownOpen($dropdown) {
        this.ea.publish('sio_choice_dropdown_open', {choice: this, $dropdown});
    }

    sanitizedConfigLabel (){
      return this.config?.label?.replace(/\./g, '-') || ""
    }
}
